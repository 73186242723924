import {Grid, Container, Typography} from "@material-ui/core";
import React, {createRef, useState, useRef, useEffect} from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Button from "@material-ui/core/Button";
import {createFileName, useScreenshot} from "use-react-screenshot";
import moment from "moment";
import {useHistory, useLocation} from "react-router-dom";

export default function RegisterComplete(){
    const [registerConfirm, setRegisterConfirm] = useState({user_id:"ufyu9999999", password:"pwd9999999"})
    const location = useLocation();
    const ref = createRef( null );
    const history = useHistory()

    const [image, takeScreenshot] = useScreenshot({
        type: "image/jpeg",
        quality: 2.0
    })

    const getImage = () => {
        takeScreenshot(ref.current);
        downloadScreenshot();
    }
    const download = ( image, { name = "img" } = {} ) => {
        const a = document.createElement( "a" );
        a.href = image;
        a.download = createFileName( registerConfirm.user_id + "_" + moment().format('DD_MM_YY_HH_mm_ss') );
        a.click();
        //handleClose()
    };

    const downloadScreenshot = () => takeScreenshot( ref.current ).then( download );

    const toLoginPage = () => {
        history.push({pathname:"/login", state:{user_id: registerConfirm.user_id}})
    };

    useEffect(()=>{
        //console.log("location.username: ", location.state)
        // console.log("location.password: ", location.state.password)
        if(location.state === undefined){
            history.push("/login")
            return
        }
        setRegisterConfirm({
            user_id: location.state.username,
            password:location.state.password
        })
    },[])

        return(
        <Container >
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={2}
            >
                <Grid item xs={10} md={4} xl={4}>
                    <a href={`${window.location.origin.replace("customer.", "")}`} target="_blank" rel="noopener noreferrer">
                        <img src={process.env.REACT_APP_LOGO_PATH} alt={process.env.REACT_APP_MONEY_SITE_NAME}
                             height="100"/>
                    </a>
                    <>
                        <div ref={ref} style={{background:"black"}}>
                        {
                            (process.env.REACT_APP_DOMAIN_PATH)?
                                (<Typography variant={"h6"} align={"center"} style={{padding:'4px'}}><b>{process.env.REACT_APP_DOMAIN_PATH}</b></Typography>):
                                (<Typography variant={"h6"} align={"center"} style={{padding:'4px'}}><b>{process.env.REACT_APP_TITLE_INDEX}</b></Typography>)
                        }

                        <Typography variant={"h6"} align={"center"} style={{padding:'4px'}}>
                            <AccountCircleIcon/>
                            <b>รหัสยูส (Username)</b>
                            {/*<IconButton onClick={()=>alert('username')} color={'primary'}>*/}
                            {/*<FileCopySharpIcon fontSize={"small"} />*/}
                            {/*</IconButton>*/}
                        </Typography>
                        <Typography variant={"h6"} align={"center"} style={{padding:'4px'}}>
                            {/*<AccountCircleIcon/>*/}
                            <b>{registerConfirm.user_id}</b>
                            {/*<IconButton onClick={()=>alert('username')} color={'primary'}>*/}
                            {/*<FileCopySharpIcon fontSize={"small"} />*/}
                            {/*</IconButton>*/}
                        </Typography>
                        <Typography variant={"h6"} align={"center"} style={{padding:'4px'}}><VpnKeyIcon/>
                            <b>พาสเวิร์ด (Password)</b>
                            {/*<IconButton onClick={() => alert( 'password' )} color={'secondary'}>*/}
                            {/*    <FileCopySharpIcon fontSize={"small"}/>*/}
                            {/*</IconButton>*/}
                        </Typography>
                        <Typography variant={"h6"} align={"center"} color={"secondary"} style={{padding:'4px'}}>
                            <b>{registerConfirm.password}</b>
                        </Typography>
                        </div>
                        <Typography variant={"h6"} align={"center"} style={{padding:'4px'}}>
                            <Button variant={"contained"} color={"primary"} size={"large"} fullWidth={true} onClick={()=>getImage()}>กดเพื่อบันทึกข้อมูลการสมัคร</Button>
                        </Typography>
                        <Typography variant={"h6"} align={"center"} style={{padding:'4px'}}>
                            <Button variant={"contained"} color={"primary"} size={"large"} fullWidth={true} onClick={()=>toLoginPage()}>กลับไปหน้า Login</Button>
                        </Typography>
                    </>
                </Grid>
            </Grid>
        </Container>
    )
}